import React from 'react';
import { string, shape, func } from 'prop-types';
import { LazyHydrate as Hydrator } from 'nordic/lazy';
import Script from 'nordic/script';
import classNames from 'classnames';

const DESKTOP_PLATFORM = 'desktop';

const UI_VPP_DESKTOP = 'ui-billboard-ad';
const UI_VPP_MOBILE = 'ui-fullscreen-ad-pdp loaded';

const AdvertisingAdn = ({ runCatchErrorBoundary = () => {}, ...props }) => {
  try {
    /* eslint-disable react-hooks/rules-of-hooks */
    const { content, title } = props;
    const { queries } = content || {};
    const { platform, slot_id, ad_unit, placement } = queries || {};
    const style = slot_id ? slot_id.includes('motors') || slot_id.includes('state') : null;
    const layout = ad_unit ? ad_unit.includes('PDP') || ad_unit.includes('UPP') : null;

    const namespace = platform === DESKTOP_PLATFORM ? UI_VPP_DESKTOP : UI_VPP_MOBILE;

    const isContextAd = placement === 'context';

    const [adLoaded, setAdLoaded] = React.useState(false);
    const ref = React.useRef(null);

    React.useEffect(() => {
      const loaded = () => setAdLoaded(true);
      const node = document.querySelector('[data-component="adn"]');

      if (node) {
        /* istanbul ignore next */
        node.addEventListener('adn-banner-loaded', loaded);
      }

      return () => {
        if (node) {
          /* istanbul ignore next */
          node.removeEventListener('adn-banner-loaded', loaded);
        }
      };
    }, []);

    return (
      <div
        id={namespace}
        className={
          isContextAd
            ? classNames('container-advertising', `${namespace} billboard-context`, { placement: layout })
            : classNames('container-advertising', namespace, { placement: style })
        }
      >
        {adLoaded && isContextAd && (
          <div className={`${namespace}__title-container`}>
            {title && <span className={`${namespace}__title`}>{title}</span>}
            {title && <div className={`${namespace}__line`} />}
          </div>
        )}
        <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
        <Hydrator ssrOnly>
          <div data-component="adn" data-content={JSON.stringify(content)} ref={ref} />
        </Hydrator>
      </div>
    );
    /* eslint-enable react-hooks/rules-of-hooks */
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

AdvertisingAdn.propTypes = {
  title: string,
  content: shape({
    category: string,
    page: string,
    placement: string,
    platform: string,
    site_id: string,
    slot_id: string,
    title: string,
  }).isRequired,
  runCatchErrorBoundary: func,
};

export default AdvertisingAdn;
